export const affiliate = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.2759 6.9599C10.7989 7.29998 10.2151 7.50002 9.58464 7.50002C7.97381 7.50002 6.66797 6.19418 6.66797 4.58335C6.66797 2.97252 7.97381 1.66669 9.58464 1.66669C10.6288 1.66669 11.5448 2.21537 12.0601 3.04014M5.0013 16.7393H7.17654C7.46016 16.7393 7.74204 16.7731 8.01698 16.8406L10.3154 17.3991C10.8141 17.5206 11.3336 17.5324 11.8375 17.4345L14.3788 16.9401C15.0501 16.8093 15.6676 16.4879 16.1516 16.0171L17.9495 14.2681C18.463 13.7695 18.463 12.9604 17.9495 12.4609C17.4873 12.0112 16.7552 11.9606 16.2322 12.3419L14.1368 13.8707C13.8367 14.0901 13.4716 14.2082 13.096 14.2082H11.0726L12.3605 14.2082C13.0865 14.2082 13.6745 13.6361 13.6745 12.9299V12.6743C13.6745 12.0879 13.2643 11.5767 12.6797 11.4349L10.6918 10.9515C10.3683 10.873 10.037 10.8334 9.70392 10.8334C8.89991 10.8334 7.44455 11.499 7.44455 11.499L5.0013 12.5208M16.668 5.41669C16.668 7.02752 15.3621 8.33335 13.7513 8.33335C12.1405 8.33335 10.8346 7.02752 10.8346 5.41669C10.8346 3.80586 12.1405 2.50002 13.7513 2.50002C15.3621 2.50002 16.668 3.80586 16.668 5.41669ZM1.66797 12.1667L1.66797 17C1.66797 17.4667 1.66797 17.7001 1.7588 17.8783C1.83869 18.0351 1.96617 18.1626 2.12298 18.2425C2.30124 18.3334 2.53459 18.3334 3.0013 18.3334H3.66797C4.13468 18.3334 4.36803 18.3334 4.54629 18.2425C4.7031 18.1626 4.83058 18.0351 4.91047 17.8783C5.0013 17.7001 5.0013 17.4667 5.0013 17V12.1667C5.0013 11.7 5.0013 11.4666 4.91047 11.2884C4.83058 11.1316 4.7031 11.0041 4.54629 10.9242C4.36803 10.8334 4.13468 10.8334 3.66797 10.8334L3.0013 10.8334C2.53459 10.8334 2.30124 10.8334 2.12298 10.9242C1.96618 11.0041 1.83869 11.1316 1.7588 11.2884C1.66797 11.4666 1.66797 11.7 1.66797 12.1667Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

import { check } from "./check";
import { close } from "./close";
import { largeArrow } from "./largeArrow";
import { largeArrowAlt } from "./largeArrowAlt";
import { arrow } from "./arrow";
import { arrowRightAlt } from "./arrowRightAlt";
import { arrowBottomAlt } from "./arrowButtonAlt";
import { cart } from "./cart";
import { user } from "./user";
import { clock } from "./clock";
import { calendar } from "./calendar";
import { search } from "./search";
import { facebook } from "./facebook";
import { twitter } from "./twitter";
import { youtube } from "./youtube";
import { instagram } from "./instagram";
import { linkedin } from "./linkedin";
import { arrowRight } from "./arrowRight";
import { folder } from "./folder";
import { file } from "./file";
import { like } from "./like";
import { dislike } from "./dislike";
import { arrowBottomDocs } from "./arrowBottomDocs";
import { home } from "./home";
import { copy } from "./copy";
import { rocket } from "./rocket";
import { email } from "./email";
import { print } from "./print";
import { curvedArrow } from "./curvedArrow";
import { secure } from "./secure";
import { phone } from "./phone";
import { openEmail } from "./openEmail";
import { emptyCartBag } from "./emptyCartBag";
import { openEye } from "./openEye";
import { closeEye } from "./closeEye";
import { download } from "./download";
import { smallClock } from "./small-clock";
import { doc } from "./doc";
import { globe } from "./globe";
import { key } from "./key";
import { squareCheck } from "./squareCheck";
import { orders } from "./orders";
import { downloads } from "./downloads";
import { licenses } from "./licenses";
import { support } from "./support";
import { profile } from "./profile";
import { affiliate } from "./affiliate";
import { message } from "./message";
import { logout } from "./logout";
import { education } from "./education";
import { info } from "./info";
import { question } from "./question";
import { layout } from "./layout";
import { changelog } from "./changelog";
import { rotateArrows } from "./rotateArrows";
import { timeCalendar } from "./timeCalendar";
import { loveCheck } from "./loveCheck";
import { grid } from "./grid";
import { puzzle } from "./puzzle";
import { mobile } from "./mobile";
import { leftArrow } from "./leftArrow";
import { arrowLongRight } from "./arrowLongRight";

export const getIcon = (iconsName) => {
    let icon;
    switch (iconsName) {
        case "mobile":
            icon = mobile;
            break;
        case "loveCheck":
            icon = loveCheck;
            break;
        case "puzzle":
            icon = puzzle;
            break;
        case "grid":
            icon = grid;
            break;
        case "timeCalendar":
            icon = timeCalendar;
            break;
        case "rotateArrows":
            icon = rotateArrows;
            break;
        case "changelog":
            icon = changelog;
            break;
        case "layout":
            icon = layout;
            break;
        case "question":
            icon = question;
            break;
        case "info":
            icon = info;
            break;
        case "education":
            icon = education;
            break;
        case "logout":
            icon = logout;
            break;
        case "message":
            icon = message;
            break;
        case "affiliate":
            icon = affiliate;
            break;
        case "profile":
            icon = profile;
            break;
        case "support":
            icon = support;
            break;
        case "licenses":
            icon = licenses;
            break;
        case "downloads":
            icon = downloads;
            break;
        case "orders":
            icon = orders;
            break;
        case "squareCheck":
            icon = squareCheck;
            break;
        case "key":
            icon = key;
            break;
        case "globe":
            icon = globe;
            break;
        case "doc":
            icon = doc;
            break;
        case "smallClock":
            icon = smallClock;
            break;
        case "download":
            icon = download;
            break;
        case "closeEye":
            icon = closeEye;
            break;
        case "openEye":
            icon = openEye;
            break;
        case "check":
            icon = check;
            break;
        case "close":
            icon = close;
            break;
        case "largeArrow":
            icon = largeArrow;
            break;
        case "largeArrowAlt":
            icon = largeArrowAlt;
            break;
        case "arrow":
            icon = arrow;
            break;
        case "arrowRight":
            icon = arrowRight;
            break;
        case "arrowRightAlt":
            icon = arrowRightAlt;
            break;
        case "arrowBottomAlt":
            icon = arrowBottomAlt;
            break;
        case "cart":
            icon = cart;
            break;
        case "user":
            icon = user;
            break;
        case "clock":
            icon = clock;
            break;
        case "calendar":
            icon = calendar;
            break;
        case "search":
            icon = search;
            break;
        case "facebook":
            icon = facebook;
            break;
        case "twitter":
            icon = twitter;
            break;
        case "youtube":
            icon = youtube;
            break;
        case "instagram":
            icon = instagram;
            break;
        case "linkedin":
            icon = linkedin;
            break;
        case "folder":
            icon = folder;
            break;
        case "file":
            icon = file;
            break;
        case "like":
            icon = like;
            break;
        case "dislike":
            icon = dislike;
            break;
        case "arrowBottomDocs":
            icon = arrowBottomDocs;
            break;
        case "home":
            icon = home;
            break;
        case "copy":
            icon = copy;
            break;
        case "rocket":
            icon = rocket;
            break;
        case "email":
            icon = email;
            break;
        case "print":
            icon = print;
            break;
        case "curvedArrow":
            icon = curvedArrow;
            break;
        case "secure":
            icon = secure;
            break;
        case "phone":
            icon = phone;
            break;
        case "openEmail":
            icon = openEmail;
            break;
        case "emptyCartBag":
            icon = emptyCartBag;
            break;
        case "leftArrow":
            icon = leftArrow;
            break;
        case "arrowLongRight":
            icon = arrowLongRight;
            break;
        default:
            null;
    }
    return icon;
};

export const key = (
    <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width={24} height={24} rx={4} fill="#B4BBCB" />
        <path
            d="M13.82 4C13.001 4.00113 12.1939 4.2234 11.4645 4.64867C10.7351 5.07394 10.104 5.69016 9.62285 6.44705C9.14166 7.20394 8.82395 8.08006 8.69567 9.00391C8.56739 9.92775 8.63216 10.8732 8.88469 11.763L5.14917 16.0222C5.05281 16.1351 4.99923 16.2868 5.00001 16.4444V19.4074C5.00001 19.5646 5.05467 19.7153 5.15197 19.8264C5.24927 19.9376 5.38123 20 5.51883 20H8.11294C8.25054 20 8.38251 19.9376 8.47981 19.8264C8.5771 19.7153 8.63177 19.5646 8.63177 19.4074V18.2222H9.66941C9.80701 18.2222 9.93897 18.1598 10.0363 18.0487C10.1336 17.9375 10.1882 17.7868 10.1882 17.6296V16.4444H11.2259C11.3639 16.4453 11.4967 16.3841 11.5955 16.2741L12.2116 15.563C12.9246 15.827 13.6792 15.9114 14.4234 15.8105C15.1677 15.7096 15.8839 15.4257 16.523 14.9784C17.162 14.531 17.7085 13.9309 18.1251 13.2192C18.5416 12.5075 18.8181 11.7012 18.9357 10.8557C19.0533 10.0103 19.0092 9.14576 18.8063 8.32168C18.6034 7.49759 18.2466 6.7335 17.7604 6.08198C17.2742 5.43046 16.6703 4.90698 15.99 4.54757C15.3098 4.18817 14.5695 4.00136 13.82 4ZM15.117 9.62963C14.9118 9.62963 14.7112 9.56012 14.5406 9.42989C14.3699 9.29966 14.2369 9.11456 14.1584 8.89799C14.0798 8.68143 14.0593 8.44313 14.0993 8.21323C14.1394 7.98332 14.2382 7.77214 14.3833 7.60639C14.5284 7.44064 14.7133 7.32776 14.9146 7.28203C15.1159 7.2363 15.3245 7.25977 15.5141 7.34948C15.7037 7.43918 15.8658 7.59109 15.9798 7.78599C16.0938 7.98089 16.1547 8.21004 16.1547 8.44444C16.1547 8.75878 16.0454 9.06023 15.8508 9.2825C15.6562 9.50476 15.3922 9.62963 15.117 9.62963Z"
            fill="white"
        />
    </svg>
);

export const folder = (
    <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.66732 3.66667L7.92362 2.17928C7.70959 1.7512 7.60256 1.53715 7.4429 1.38078C7.3017 1.24249 7.13153 1.13732 6.9447 1.07287C6.73343 1 6.49412 1 6.01551 1H3.46732C2.72058 1 2.34721 1 2.062 1.14532C1.81111 1.27316 1.60714 1.47713 1.47931 1.72801C1.33398 2.01323 1.33398 2.3866 1.33398 3.13333V3.66667M1.33398 3.66667H11.4673C12.5874 3.66667 13.1475 3.66667 13.5753 3.88465C13.9516 4.0764 14.2576 4.38236 14.4493 4.75869C14.6673 5.18651 14.6673 5.74656 14.6673 6.86667V9.8C14.6673 10.9201 14.6673 11.4802 14.4493 11.908C14.2576 12.2843 13.9516 12.5903 13.5753 12.782C13.1475 13 12.5874 13 11.4673 13H4.53398C3.41388 13 2.85383 13 2.426 12.782C2.04968 12.5903 1.74372 12.2843 1.55197 11.908C1.33398 11.4802 1.33398 10.9201 1.33398 9.8V3.66667Z"
            stroke="var(--primary)"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const dislike = (
    <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.834 1.33337V8.66671M14.1674 6.53337V3.46671C14.1674 2.71997 14.1674 2.3466 14.022 2.06139C13.8942 1.8105 13.6902 1.60653 13.4393 1.4787C13.1541 1.33337 12.7808 1.33337 12.034 1.33337H4.91269C3.93837 1.33337 3.45121 1.33337 3.05774 1.51166C2.71095 1.6688 2.41622 1.92166 2.20818 2.24052C1.97213 2.6023 1.89806 3.0838 1.7499 4.04679L1.40119 6.31346C1.20578 7.58358 1.10808 8.21864 1.29656 8.71279C1.46198 9.1465 1.77312 9.50917 2.17663 9.73862C2.63637 10 3.27891 10 4.56398 10H5.10069C5.47405 10 5.66074 10 5.80335 10.0727C5.92879 10.1366 6.03078 10.2386 6.09469 10.364C6.16735 10.5067 6.16735 10.6933 6.16735 11.0667V13.0228C6.16735 13.9307 6.90335 14.6667 7.81125 14.6667C8.0278 14.6667 8.22403 14.5392 8.31198 14.3413L10.5525 9.30016C10.6544 9.07087 10.7053 8.95623 10.7859 8.87217C10.8571 8.79786 10.9445 8.74104 11.0413 8.70616C11.1508 8.66671 11.2763 8.66671 11.5272 8.66671H12.034C12.7808 8.66671 13.1541 8.66671 13.4393 8.52138C13.6902 8.39355 13.8942 8.18958 14.022 7.93869C14.1674 7.65348 14.1674 7.28011 14.1674 6.53337Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const education = (
    <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.5 19H3C1.89543 19 1 18.1046 1 17V3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V17C19 18.1046 18.1046 19 17 19H15.5M10 18C11.6569 18 13 16.6569 13 15C13 13.3431 11.6569 12 10 12C8.34315 12 7 13.3431 7 15C7 16.6569 8.34315 18 10 18ZM10 18L10.0214 17.9998L6.82867 21.1926L4.00024 18.3641L7.01965 15.3447M10 18L13.1928 21.1926L16.0212 18.3641L13.0018 15.3447M7 5H13M5 8.5H15"
            stroke="#98A2B3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const arrowLongRight = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="jlt-w-4 jlt-h-4"
    >
        <path
            d="M19 3L8 3M19 17L8 17M19 10L8 10M4 3C4 3.82843 3.32843 4.5 2.5 4.5C1.67157 4.5 1 3.82843 1 3C1 2.17157 1.67157 1.5 2.5 1.5C3.32843 1.5 4 2.17157 4 3ZM4 17C4 17.8284 3.32843 18.5 2.5 18.5C1.67157 18.5 1 17.8284 1 17C1 16.1716 1.67157 15.5 2.5 15.5C3.32843 15.5 4 16.1716 4 17ZM4 10C4 10.8284 3.32843 11.5 2.5 11.5C1.67157 11.5 1 10.8284 1 10C1 9.17157 1.67157 8.5 2.5 8.5C3.32843 8.5 4 9.17157 4 10Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

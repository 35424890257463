export const close = (
    <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.17675 0.198476C0.903145 -0.0708601 0.464609 -0.0653881 0.197251 0.210698C-0.0701059 0.486785 -0.0650416 0.928938 0.208563 1.19827L4.07053 5L0.208563 8.80173C-0.0650416 9.07106 -0.0701058 9.51322 0.197252 9.7893C0.464609 10.0654 0.903145 10.0709 1.17675 9.80152L5.06245 5.97644L8.82325 9.67858C9.09686 9.94792 9.53539 9.94245 9.80275 9.66636C10.0701 9.39027 10.065 8.94812 9.79144 8.67878L6.05436 5L9.79144 1.32122C10.065 1.05188 10.0701 0.609726 9.80275 0.333639C9.53539 0.0575529 9.09686 0.0520812 8.82325 0.321418L5.06245 4.02356L1.17675 0.198476Z"
            fill="#B4BBCB"
        />
    </svg>
);

export const cart = (
    <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.33188 19.7215C8.01191 19.7215 6.94263 20.7959 6.94263 22.1107C6.94263 23.4256 8.01702 24.5 9.33188 24.5C10.6467 24.5 11.7211 23.4256 11.7211 22.1107C11.7211 20.7959 10.6519 19.7215 9.33188 19.7215ZM9.33188 22.9242C8.88166 22.9242 8.51841 22.561 8.51841 22.1107C8.51841 21.6605 8.88166 21.2973 9.33188 21.2973C9.78211 21.2973 10.1454 21.6605 10.1454 22.1107C10.1454 22.561 9.77699 22.9242 9.33188 22.9242Z"
            fill="#16213E"
        />
        <path
            d="M18.94 19.7215C17.6201 19.7215 16.5508 20.7959 16.5508 22.1107C16.5508 23.4256 17.6252 24.5 18.94 24.5C20.2549 24.5 21.3293 23.4256 21.3293 22.1107C21.3293 20.7959 20.26 19.7215 18.94 19.7215ZM19.7535 22.1107C19.7535 22.561 19.3903 22.9242 18.94 22.9242C18.4898 22.9242 18.1266 22.561 18.1266 22.1107C18.1266 21.6605 18.4898 21.2973 18.94 21.2973C19.3903 21.2973 19.7535 21.6605 19.7535 22.1107Z"
            fill="#16213E"
        />
        <path
            d="M23.2786 3.91249C23.1609 3.77947 22.9921 3.70273 22.8182 3.70273H6.24174L6.06267 2.53112C5.87849 1.37487 4.85014 0.5 3.67342 0.5H0V2.0809H3.67342C4.09806 2.0809 4.44596 2.37252 4.48689 2.77158L6.73289 17.2504C6.97335 18.3913 7.97612 19.215 9.11703 19.215H20.7973V17.6392H9.11703C8.71285 17.6392 8.38542 17.3578 8.30356 16.9434L8.13984 15.9611H19.6922C20.8638 15.9611 21.8461 15.1067 22.0814 13.8737L22.6544 10.0417L23.4321 4.39853C23.4526 4.21946 23.3963 4.04551 23.2786 3.91249ZM21.7182 5.33479L20.9303 10.85L20.5057 13.6895C20.4238 14.1449 20.1015 14.4365 19.6922 14.4365H7.89427L6.4822 5.33479H21.7182Z"
            fill="#16213E"
        />
    </svg>
);

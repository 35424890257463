export const doc = (
    <svg
        width={13}
        height={14}
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.10643 0C1.03585 0 0.167969 0.81252 0.167969 1.81481V12.1852C0.167969 13.1875 1.03585 14 2.10643 14H10.2295C11.3001 14 12.168 13.1875 12.168 12.1852V1.81481C12.168 0.812521 11.3001 0 10.2295 0H2.10643ZM1.82951 1.81481C1.82951 1.67163 1.95349 1.55556 2.10643 1.55556H10.2295C10.3824 1.55556 10.5064 1.67163 10.5064 1.81481V12.1852C10.5064 12.3284 10.3824 12.4444 10.2295 12.4444H2.10643C1.95349 12.4444 1.82951 12.3284 1.82951 12.1852V1.81481ZM3.95258 3.45679C3.49376 3.45679 3.12181 3.80501 3.12181 4.23457C3.12181 4.66412 3.49376 5.01235 3.95258 5.01235H5.42951C5.88833 5.01235 6.26028 4.66412 6.26028 4.23457C6.26028 3.80501 5.88833 3.45679 5.42951 3.45679H3.95258ZM3.12181 7C3.12181 6.57045 3.49376 6.22222 3.95258 6.22222H6.90643C7.36525 6.22222 7.7372 6.57045 7.7372 7C7.7372 7.42955 7.36525 7.77778 6.90643 7.77778H3.95258C3.49376 7.77778 3.12181 7.42955 3.12181 7ZM3.95258 8.98765C3.49376 8.98765 3.12181 9.33588 3.12181 9.76543C3.12181 10.195 3.49376 10.5432 3.95258 10.5432H8.38335C8.84217 10.5432 9.21412 10.195 9.21412 9.76543C9.21412 9.33588 8.84217 8.98765 8.38335 8.98765H3.95258Z"
            fill="white"
        />
    </svg>
);

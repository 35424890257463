export const support = (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.57627 7.50001C7.77219 6.94307 8.1589 6.47343 8.6679 6.17429C9.1769 5.87514 9.77535 5.76579 10.3573 5.86561C10.9392 5.96542 11.467 6.26795 11.8472 6.71962C12.2274 7.17129 12.4355 7.74295 12.4346 8.33335C12.4346 10 9.93461 10.8333 9.93461 10.8333M10.0013 14.1667H10.0096M1.66797 7.10231V12.8977C1.66797 13.1016 1.66797 13.2035 1.69099 13.2994C1.71141 13.3844 1.74508 13.4657 1.79077 13.5403C1.8423 13.6244 1.91437 13.6964 2.05849 13.8405L6.16078 17.9428C6.3049 18.087 6.37697 18.159 6.46106 18.2106C6.53563 18.2562 6.61691 18.2899 6.70194 18.3103C6.79785 18.3334 6.89976 18.3334 7.10359 18.3334H12.899C13.1028 18.3334 13.2048 18.3334 13.3007 18.3103C13.3857 18.2899 13.467 18.2562 13.5415 18.2106C13.6256 18.159 13.6977 18.087 13.8418 17.9428L17.9441 13.8405C18.0882 13.6964 18.1603 13.6244 18.2118 13.5403C18.2575 13.4657 18.2912 13.3844 18.3116 13.2994C18.3346 13.2035 18.3346 13.1016 18.3346 12.8977V7.10231C18.3346 6.89848 18.3346 6.79657 18.3116 6.70066C18.2912 6.61563 18.2575 6.53434 18.2118 6.45978C18.1603 6.37569 18.0882 6.30362 17.9441 6.1595L13.8418 2.05721C13.6977 1.91308 13.6256 1.84102 13.5415 1.78949C13.467 1.7438 13.3857 1.71013 13.3007 1.68971C13.2048 1.66669 13.1028 1.66669 12.899 1.66669H7.10359C6.89976 1.66669 6.79785 1.66669 6.70194 1.68971C6.61691 1.71013 6.53563 1.7438 6.46106 1.78949C6.37697 1.84102 6.3049 1.91308 6.16078 2.05721L2.05849 6.1595C1.91437 6.30362 1.8423 6.37569 1.79077 6.45978C1.74508 6.53434 1.71141 6.61563 1.69099 6.70066C1.66797 6.79657 1.66797 6.89848 1.66797 7.10231Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

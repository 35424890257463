export const phone = (
    <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.28325 6.63989C5.80525 7.72711 6.51684 8.74609 7.41803 9.64727C8.31921 10.5485 9.33819 11.26 10.4254 11.782C10.5189 11.8269 10.5657 11.8494 10.6249 11.8666C10.8351 11.9279 11.0933 11.8839 11.2714 11.7564C11.3215 11.7205 11.3644 11.6777 11.4501 11.5919C11.7123 11.3297 11.8434 11.1986 11.9752 11.1129C12.4724 10.7897 13.1133 10.7897 13.6105 11.1129C13.7423 11.1986 13.8734 11.3297 14.1356 11.5919L14.2818 11.7381C14.6804 12.1367 14.8797 12.3359 14.9879 12.55C15.2032 12.9756 15.2032 13.4783 14.9879 13.904C14.8797 14.1181 14.6804 14.3173 14.2818 14.7159L14.1636 14.8342C13.7664 15.2314 13.5677 15.43 13.2977 15.5817C12.9981 15.75 12.5327 15.871 12.1891 15.87C11.8793 15.8691 11.6677 15.809 11.2443 15.6888C8.9693 15.0431 6.82253 13.8247 5.03154 12.0338C3.24056 10.2428 2.0222 8.096 1.37647 5.82095C1.25632 5.39762 1.19624 5.18595 1.19532 4.87624C1.1943 4.53258 1.31532 4.06721 1.48363 3.76758C1.63532 3.49755 1.83393 3.29894 2.23114 2.90173L2.34937 2.7835C2.74795 2.38492 2.94724 2.18563 3.16128 2.07737C3.58695 1.86207 4.08965 1.86207 4.51532 2.07737C4.72936 2.18563 4.92865 2.38492 5.32723 2.7835L5.47338 2.92965C5.73559 3.19186 5.86669 3.32296 5.9524 3.45479C6.27565 3.95196 6.27565 4.5929 5.9524 5.09007C5.86669 5.2219 5.73559 5.353 5.47338 5.61521C5.38765 5.70094 5.34478 5.74381 5.3089 5.79392C5.18139 5.97199 5.13736 6.23018 5.19866 6.44045C5.2159 6.49962 5.23835 6.54637 5.28325 6.63989Z"
            stroke="#667085"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const largeArrow = (
    <svg
        width="26"
        height="12"
        viewBox="0 0 26 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M25.4559 6.53033C25.7488 6.23744 25.7488 5.76256 25.4559 5.46967L20.6829 0.696699C20.39 0.403806 19.9151 0.403806 19.6222 0.696699C19.3293 0.989593 19.3293 1.46447 19.6222 1.75736L23.8649 6L19.6222 10.2426C19.3293 10.5355 19.3293 11.0104 19.6222 11.3033C19.9151 11.5962 20.39 11.5962 20.6829 11.3033L25.4559 6.53033ZM0.925537 6.75H24.9255V5.25H0.925537V6.75Z"
            fill="#256EFF"
        />
    </svg>
);

// <svg
//         width="17"
//         height="8"
//         viewBox="0 0 17 8"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//     >
//         <path d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z" />
//     </svg>

export const orders = (
    <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.6 2.20002L2.3 3.93335C2.04251 4.27667 1.91377 4.44833 1.91676 4.59201C1.91936 4.71705 1.97799 4.83431 2.07646 4.91142C2.18962 5.00002 2.40419 5.00002 2.83333 5.00002H15.1667C15.5958 5.00002 15.8104 5.00002 15.9235 4.91142C16.022 4.83431 16.0806 4.71705 16.0832 4.59201C16.0862 4.44833 15.9575 4.27667 15.7 3.93335L14.4 2.20002M3.6 2.20002C3.74667 2.00447 3.82 1.90669 3.91294 1.83617C3.99525 1.77371 4.08846 1.7271 4.18782 1.69872C4.3 1.66669 4.42222 1.66669 4.66667 1.66669H13.3333C13.5778 1.66669 13.7 1.66669 13.8122 1.69872C13.9115 1.7271 14.0047 1.77371 14.0871 1.83617C14.18 1.90669 14.2533 2.00447 14.4 2.20002M3.6 2.20002L2.03333 4.28891C1.83545 4.55275 1.73651 4.68468 1.66625 4.82996C1.6039 4.95887 1.55843 5.09528 1.53096 5.23582C1.5 5.3942 1.5 5.5591 1.5 5.88891L1.5 15.6667C1.5 16.6001 1.5 17.0668 1.68166 17.4233C1.84144 17.7369 2.09641 17.9919 2.41002 18.1517C2.76654 18.3334 3.23325 18.3334 4.16667 18.3334L13.8333 18.3334C14.7668 18.3334 15.2335 18.3334 15.59 18.1517C15.9036 17.9919 16.1586 17.7369 16.3183 17.4233C16.5 17.0668 16.5 16.6001 16.5 15.6667V5.88891C16.5 5.5591 16.5 5.3942 16.469 5.23582C16.4416 5.09528 16.3961 4.95887 16.3338 4.82996C16.2635 4.68468 16.1646 4.55275 15.9667 4.28891L14.4 2.20002M12.3333 8.33335C12.3333 9.21741 11.9821 10.0653 11.357 10.6904C10.7319 11.3155 9.88405 11.6667 9 11.6667C8.11594 11.6667 7.2681 11.3155 6.64298 10.6904C6.01786 10.0653 5.66667 9.21741 5.66667 8.33335"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const print = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.0026 4.66665V3.46665C12.0026 2.71991 12.0026 2.34654 11.8573 2.06133C11.7294 1.81044 11.5255 1.60647 11.2746 1.47864C10.9894 1.33331 10.616 1.33331 9.86927 1.33331H6.13594C5.3892 1.33331 5.01583 1.33331 4.73062 1.47864C4.47973 1.60647 4.27576 1.81044 4.14793 2.06133C4.0026 2.34654 4.0026 2.71991 4.0026 3.46665V4.66665M4.0026 12C3.38262 12 3.07263 12 2.8183 11.9318C2.12812 11.7469 1.58902 11.2078 1.40409 10.5176C1.33594 10.2633 1.33594 9.95329 1.33594 9.33331V7.86665C1.33594 6.74654 1.33594 6.18649 1.55392 5.75867C1.74567 5.38234 2.05163 5.07638 2.42796 4.88463C2.85578 4.66665 3.41583 4.66665 4.53594 4.66665H11.4693C12.5894 4.66665 13.1494 4.66665 13.5773 4.88463C13.9536 5.07638 14.2595 5.38234 14.4513 5.75867C14.6693 6.18649 14.6693 6.74654 14.6693 7.86665V9.33331C14.6693 9.95329 14.6693 10.2633 14.6011 10.5176C14.4162 11.2078 13.8771 11.7469 13.1869 11.9318C12.9326 12 12.6226 12 12.0026 12M10.0026 6.99998H12.0026M6.13594 14.6666H9.86927C10.616 14.6666 10.9894 14.6666 11.2746 14.5213C11.5255 14.3935 11.7294 14.1895 11.8573 13.9386C12.0026 13.6534 12.0026 13.2801 12.0026 12.5333V11.4666C12.0026 10.7199 12.0026 10.3465 11.8573 10.0613C11.7294 9.81044 11.5255 9.60647 11.2746 9.47864C10.9894 9.33331 10.616 9.33331 9.86927 9.33331H6.13594C5.3892 9.33331 5.01583 9.33331 4.73062 9.47864C4.47973 9.60647 4.27576 9.81044 4.14793 10.0613C4.0026 10.3465 4.0026 10.7199 4.0026 11.4666V12.5333C4.0026 13.28 4.0026 13.6534 4.14793 13.9386C4.27576 14.1895 4.47973 14.3935 4.73062 14.5213C5.01583 14.6666 5.3892 14.6666 6.13594 14.6666Z"
            stroke="#344054"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

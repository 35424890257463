export const calendar = (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.5 6.66665H2.5M11.1667 1.33331V3.99998M5.83333 1.33331V3.99998M5.7 14.6666H11.3C12.4201 14.6666 12.9802 14.6666 13.408 14.4487C13.7843 14.2569 14.0903 13.951 14.282 13.5746C14.5 13.1468 14.5 12.5868 14.5 11.4666V5.86665C14.5 4.74654 14.5 4.18649 14.282 3.75867C14.0903 3.38234 13.7843 3.07638 13.408 2.88463C12.9802 2.66665 12.4201 2.66665 11.3 2.66665H5.7C4.5799 2.66665 4.01984 2.66665 3.59202 2.88463C3.21569 3.07638 2.90973 3.38234 2.71799 3.75867C2.5 4.18649 2.5 4.74654 2.5 5.86665V11.4666C2.5 12.5868 2.5 13.1468 2.71799 13.5746C2.90973 13.951 3.21569 14.2569 3.59202 14.4487C4.01984 14.6666 4.5799 14.6666 5.7 14.6666Z"
            stroke="#98A2B3"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

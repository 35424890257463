export const youtube = (
    <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="38" height="38" rx="2" fill="#FF0000" />
        <path
            d="M28.539 14.1905C28.31 13.3283 27.6353 12.6492 26.7787 12.4188C25.2259 12 18.9995 12 18.9995 12C18.9995 12 12.7732 12 11.2204 12.4188C10.3637 12.6493 9.689 13.3283 9.46001 14.1905C9.04395 15.7534 9.04395 19.0141 9.04395 19.0141C9.04395 19.0141 9.04395 22.2749 9.46001 23.8378C9.689 24.7 10.3637 25.3508 11.2204 25.5812C12.7732 26 18.9995 26 18.9995 26C18.9995 26 25.2259 26 26.7787 25.5812C27.6353 25.3508 28.31 24.7 28.539 23.8378C28.9551 22.2749 28.9551 19.0141 28.9551 19.0141C28.9551 19.0141 28.9551 15.7534 28.539 14.1905ZM16.9631 21.9747V16.0536L22.1672 19.0142L16.9631 21.9747Z"
            fill="white"
        />
    </svg>
);

export const squareCheck = (
    <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.5 10.8352L8.5 13.8352L14.5 7.83521M5.8 19.8352H14.2C15.8802 19.8352 16.7202 19.8352 17.362 19.5082C17.9265 19.2206 18.3854 18.7617 18.673 18.1972C19 17.5554 19 16.7154 19 15.0352V6.63521C19 4.95505 19 4.11497 18.673 3.47323C18.3854 2.90875 17.9265 2.44981 17.362 2.16219C16.7202 1.83521 15.8802 1.83521 14.2 1.83521H5.8C4.11984 1.83521 3.27976 1.83521 2.63803 2.16219C2.07354 2.44981 1.6146 2.90875 1.32698 3.47323C1 4.11497 1 4.95505 1 6.6352V15.0352C1 16.7154 1 17.5554 1.32698 18.1972C1.6146 18.7617 2.07354 19.2206 2.63803 19.5082C3.27976 19.8352 4.11984 19.8352 5.8 19.8352Z"
            stroke="var(--primary)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const clock = (
    <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.49967 3.99998V7.99998L11.1663 9.33331M15.1663 7.99998C15.1663 11.6819 12.1816 14.6666 8.49967 14.6666C4.81778 14.6666 1.83301 11.6819 1.83301 7.99998C1.83301 4.31808 4.81778 1.33331 8.49967 1.33331C12.1816 1.33331 15.1663 4.31808 15.1663 7.99998Z"
            stroke="#98A2B3"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

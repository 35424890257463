export const layout = (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1 10.9996L10.6422 15.8207C10.7734 15.8863 10.839 15.9191 10.9078 15.932C10.9687 15.9434 11.0313 15.9434 11.0922 15.932C11.161 15.9191 11.2266 15.8863 11.3578 15.8207L21 10.9996M1 15.9996L10.6422 20.8207C10.7734 20.8863 10.839 20.9191 10.9078 20.932C10.9687 20.9434 11.0313 20.9434 11.0922 20.932C11.161 20.9191 11.2266 20.8863 11.3578 20.8207L21 15.9996M1 5.99958L10.6422 1.17846C10.7734 1.11287 10.839 1.08008 10.9078 1.06717C10.9687 1.05574 11.0313 1.05574 11.0922 1.06717C11.161 1.08008 11.2266 1.11287 11.3578 1.17846L21 5.99958L11.3578 10.8207C11.2266 10.8863 11.161 10.9191 11.0922 10.932C11.0313 10.9434 10.9687 10.9434 10.9078 10.932C10.839 10.9191 10.7734 10.8863 10.6422 10.8207L1 5.99958Z"
            stroke="#98A2B3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

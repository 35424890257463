export const download = (
    <svg
        width={12}
        height={12}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 8.89728C0 8.43213 0.371339 8.05505 0.829409 8.05505C1.28748 8.05505 1.65882 8.43213 1.65882 8.89728V10.0348C1.65882 10.1899 1.7826 10.3155 1.93529 10.3155H10.0647C10.2174 10.3155 10.3412 10.1899 10.3412 10.0348V8.89728C10.3412 8.43213 10.7125 8.05505 11.1706 8.05505C11.6287 8.05505 12 8.43213 12 8.89728V10.0348C12 11.1202 11.1335 12 10.0647 12H1.93529C0.866458 12 0 11.1202 0 10.0348L0 8.89728ZM3.60996 4.93589L5.14308 6.4927L5.14308 0.842226C5.14308 0.377077 5.51442 0 5.97249 0C6.43056 0 6.8019 0.377078 6.8019 0.842226L6.8019 6.4927L8.33502 4.93589C8.65893 4.60698 9.18408 4.60698 9.50799 4.93589C9.83189 5.26479 9.83189 5.79806 9.50799 6.12697L7.34095 8.32749C6.58517 9.09495 5.35981 9.09495 4.60404 8.32749L2.437 6.12697C2.1131 5.79806 2.1131 5.26479 2.437 4.93589C2.76091 4.60698 3.28606 4.60698 3.60996 4.93589Z"
            fill="white"
        />
    </svg>
);

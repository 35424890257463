export const instagram = (
    <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="38" height="38" rx="2" fill="#E1306C" />
        <path
            d="M19.0025 13.8723C16.1642 13.8723 13.8748 16.1617 13.8748 19C13.8748 21.8383 16.1642 24.1277 19.0025 24.1277C21.8408 24.1277 24.1303 21.8383 24.1303 19C24.1303 16.1617 21.8408 13.8723 19.0025 13.8723ZM19.0025 22.3337C17.1683 22.3337 15.6688 20.8387 15.6688 19C15.6688 17.1613 17.1638 15.6663 19.0025 15.6663C20.8412 15.6663 22.3362 17.1613 22.3362 19C22.3362 20.8387 20.8367 22.3337 19.0025 22.3337ZM25.536 13.6625C25.536 14.3275 25.0005 14.8585 24.34 14.8585C23.6751 14.8585 23.144 14.323 23.144 13.6625C23.144 13.002 23.6795 12.4665 24.34 12.4665C25.0005 12.4665 25.536 13.002 25.536 13.6625ZM28.9322 14.8764C28.8564 13.2742 28.4904 11.8551 27.3167 10.6858C26.1474 9.51657 24.7283 9.15062 23.1261 9.07029C21.4749 8.97657 16.5257 8.97657 14.8744 9.07029C13.2767 9.14616 11.8576 9.51211 10.6839 10.6814C9.51015 11.8506 9.14867 13.2698 9.06834 14.8719C8.97462 16.5232 8.97462 21.4724 9.06834 23.1236C9.1442 24.7258 9.51015 26.1449 10.6839 27.3142C11.8576 28.4834 13.2723 28.8494 14.8744 28.9297C16.5257 29.0234 21.4749 29.0234 23.1261 28.9297C24.7283 28.8538 26.1474 28.4879 27.3167 27.3142C28.4859 26.1449 28.8519 24.7258 28.9322 23.1236C29.0259 21.4724 29.0259 16.5276 28.9322 14.8764ZM26.799 24.8953C26.4509 25.7701 25.777 26.4439 24.8979 26.7965C23.5813 27.3186 20.4574 27.1981 19.0025 27.1981C17.5476 27.1981 14.4192 27.3142 13.1072 26.7965C12.2325 26.4484 11.5586 25.7745 11.206 24.8953C10.6839 23.5788 10.8044 20.4549 10.8044 19C10.8044 17.5451 10.6883 14.4167 11.206 13.1047C11.5541 12.2299 12.228 11.5561 13.1072 11.2035C14.4237 10.6814 17.5476 10.8019 19.0025 10.8019C20.4574 10.8019 23.5858 10.6858 24.8979 11.2035C25.7726 11.5516 26.4464 12.2255 26.799 13.1047C27.3212 14.4212 27.2007 17.5451 27.2007 19C27.2007 20.4549 27.3212 23.5833 26.799 24.8953Z"
            fill="white"
        />
    </svg>
);

export const rocket = (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.74928 10.2501L16.4993 1.50005M7.85559 10.5234L10.0457 16.1551C10.2386 16.6512 10.3351 16.8993 10.4741 16.9717C10.5946 17.0345 10.7381 17.0346 10.8587 16.9719C10.9978 16.8997 11.0946 16.6517 11.2881 16.1558L16.78 2.08272C16.9547 1.63507 17.0421 1.41124 16.9943 1.26822C16.9528 1.14401 16.8553 1.04654 16.7311 1.00504C16.5881 0.957265 16.3643 1.04461 15.9166 1.2193L1.84349 6.71125C1.34759 6.90477 1.09965 7.00153 1.02739 7.14062C0.964749 7.26119 0.964833 7.40474 1.02761 7.52523C1.10004 7.66424 1.3481 7.7607 1.84422 7.95364L7.47589 10.1437C7.5766 10.1829 7.62695 10.2025 7.66935 10.2327C7.70693 10.2595 7.7398 10.2924 7.7666 10.33C7.79685 10.3724 7.81643 10.4227 7.85559 10.5234Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

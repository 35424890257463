export const arrowRightAlt = (
    <svg
        width="10"
        height="16"
        viewBox="0 0 10 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.48797 16C1.76457 15.9988 2.02976 15.8896 2.22701 15.6957L9.18275 8.73995C9.28002 8.64302 9.3572 8.52784 9.40986 8.40102C9.46252 8.2742 9.48962 8.13823 9.48962 8.00091C9.48962 7.86358 9.46252 7.72761 9.40986 7.60079C9.3572 7.47397 9.28002 7.35879 9.18275 7.26186L2.22701 0.306123C2.03101 0.110116 1.76516 0 1.48797 0C1.35071 0 1.2148 0.0270342 1.088 0.079559C0.96119 0.132084 0.845972 0.20907 0.748919 0.306123C0.651866 0.403176 0.574879 0.518395 0.522354 0.645201C0.46983 0.772007 0.442796 0.907917 0.442796 1.04517C0.442796 1.18242 0.46983 1.31833 0.522354 1.44514C0.574879 1.57195 0.651866 1.68716 0.748919 1.78422L6.96561 8.00091L0.748919 14.2176C0.65165 14.3145 0.574472 14.4297 0.521811 14.5565C0.469151 14.6833 0.442044 14.8193 0.442044 14.9566C0.442044 15.094 0.469151 15.2299 0.521811 15.3568C0.574472 15.4836 0.65165 15.5988 0.748919 15.6957C0.946169 15.8896 1.21136 15.9988 1.48797 16Z"
            fill="#B4BBCB"
        />
    </svg>
);

export const arrowBottomAlt = (
    <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M7.79846 1.67866L4.52573 5.31502C4.4576 5.38943 4.37472 5.44885 4.28238 5.4895C4.19005 5.53014 4.09026 5.55113 3.98937 5.55113C3.88848 5.55113 3.78869 5.53014 3.69635 5.4895C3.60401 5.44885 3.52114 5.38943 3.45301 5.31502L0.180279 1.67866C0.0549595 1.53564 -0.00935947 1.34922 0.0011025 1.15935C0.0115645 0.969485 0.0959756 0.791265 0.23625 0.662878C0.376524 0.534492 0.561503 0.466152 0.751555 0.4725C0.941606 0.478849 1.12161 0.559382 1.25301 0.696843L3.98937 3.73321L6.72573 0.696843C6.78955 0.624014 6.86726 0.564663 6.95432 0.522269C7.04138 0.479875 7.13603 0.455293 7.23271 0.449966C7.3294 0.444638 7.42617 0.458672 7.51736 0.491244C7.60855 0.523816 7.69232 0.574271 7.76375 0.639648C7.83518 0.705025 7.89284 0.784007 7.93333 0.871963C7.97383 0.959919 7.99636 1.05508 7.99959 1.15186C8.00283 1.24863 7.9867 1.34508 7.95216 1.43555C7.91762 1.52601 7.86537 1.60866 7.79846 1.67866Z" />
    </svg>
);

export const message = (
    <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.5 6H4.50667M7.5 6H7.50667M10.5 6H10.5067M4.16667 11V12.557C4.16667 12.9122 4.16667 13.0898 4.23949 13.1811C4.30282 13.2604 4.39885 13.3066 4.50036 13.3065C4.61708 13.3063 4.75578 13.1954 5.03317 12.9735L6.62348 11.7012C6.94834 11.4413 7.11078 11.3114 7.29166 11.219C7.45213 11.137 7.62295 11.0771 7.79948 11.0408C7.99845 11 8.20646 11 8.6225 11H10.3C11.4201 11 11.9802 11 12.408 10.782C12.7843 10.5903 13.0903 10.2843 13.282 9.90798C13.5 9.48016 13.5 8.9201 13.5 7.8V4.2C13.5 3.07989 13.5 2.51984 13.282 2.09202C13.0903 1.71569 12.7843 1.40973 12.408 1.21799C11.9802 1 11.4201 1 10.3 1H4.7C3.5799 1 3.01984 1 2.59202 1.21799C2.21569 1.40973 1.90973 1.71569 1.71799 2.09202C1.5 2.51984 1.5 3.07989 1.5 4.2V8.33333C1.5 8.95331 1.5 9.2633 1.56815 9.51764C1.75308 10.2078 2.29218 10.7469 2.98236 10.9319C3.2367 11 3.54669 11 4.16667 11ZM4.83333 6C4.83333 6.18409 4.6841 6.33333 4.5 6.33333C4.31591 6.33333 4.16667 6.18409 4.16667 6C4.16667 5.8159 4.31591 5.66667 4.5 5.66667C4.6841 5.66667 4.83333 5.8159 4.83333 6ZM7.83333 6C7.83333 6.18409 7.6841 6.33333 7.5 6.33333C7.31591 6.33333 7.16667 6.18409 7.16667 6C7.16667 5.8159 7.31591 5.66667 7.5 5.66667C7.6841 5.66667 7.83333 5.8159 7.83333 6ZM10.8333 6C10.8333 6.18409 10.6841 6.33333 10.5 6.33333C10.3159 6.33333 10.1667 6.18409 10.1667 6C10.1667 5.8159 10.3159 5.66667 10.5 5.66667C10.6841 5.66667 10.8333 5.8159 10.8333 6Z"
            stroke="#98A2B3"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

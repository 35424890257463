export const rotateArrows = (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.54636 17.7677C9.9455 18.8321 12.803 18.7743 15.2499 17.3615C19.3154 15.0143 20.7084 9.81581 18.3612 5.75032L18.1112 5.31731M3.63826 14.2504C1.29105 10.185 2.68399 4.98644 6.74948 2.63923C9.19646 1.22646 12.0539 1.16865 14.4531 2.23301M1.49316 14.3341L4.22521 15.0661L4.95727 12.3341M17.0424 7.66614L17.7744 4.93409L20.5065 5.66614"
            stroke="#98A2B3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const user = (
    <svg
        width="11"
        height="13"
        viewBox="0 0 11 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.29167 3.73077C2.29167 4.58762 2.62969 5.40938 3.23137 6.01527C3.83305 6.62115 4.6491 6.96154 5.5 6.96154C6.3509 6.96154 7.16695 6.62115 7.76863 6.01527C8.37031 5.40938 8.70833 4.58762 8.70833 3.73077C8.70833 2.87392 8.37031 2.05216 7.76863 1.44627C7.16695 0.840384 6.3509 0.5 5.5 0.5C4.6491 0.5 3.83305 0.840384 3.23137 1.44627C2.62969 2.05216 2.29167 2.87392 2.29167 3.73077ZM10.0833 12.5H0.458333C0.336776 12.5 0.220197 12.4514 0.134243 12.3648C0.0482886 12.2783 0 12.1609 0 12.0385V10.6538C0 10.0418 0.241443 9.45484 0.671214 9.02206C1.10098 8.58928 1.68388 8.34615 2.29167 8.34615H8.70833C9.31612 8.34615 9.89902 8.58928 10.3288 9.02206C10.7586 9.45484 11 10.0418 11 10.6538V12.0385C11 12.1609 10.9517 12.2783 10.8658 12.3648C10.7798 12.4514 10.6632 12.5 10.5417 12.5H10.0833Z"
            fill="white"
        />
    </svg>
);

export const twitter = (
    <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.03168 13.5005C11.0694 13.5005 14.3718 8.49823 14.3718 4.16031C14.3718 4.01823 14.3718 3.87679 14.3622 3.73599C15.0047 3.27129 15.5593 2.69591 16 2.03679C15.4009 2.30239 14.7654 2.47649 14.1146 2.55327C14.7999 2.14306 15.3128 1.49779 15.5578 0.737586C14.9134 1.11999 14.2084 1.38947 13.4733 1.53439C12.9783 1.0081 12.3237 0.659614 11.6108 0.542842C10.8978 0.426071 10.1663 0.547529 9.52931 0.888421C8.89234 1.22931 8.38548 1.77064 8.08716 2.42862C7.78884 3.0866 7.71569 3.82456 7.87904 4.52831C6.57393 4.46284 5.29717 4.12366 4.13164 3.53279C2.9661 2.94192 1.93784 2.11256 1.1136 1.09855C0.693819 1.82121 0.565248 2.6767 0.754066 3.49083C0.942885 4.30496 1.43489 5.01652 2.12992 5.48063C1.60749 5.46532 1.09643 5.32438 0.64 5.06975V5.11135C0.640207 5.86925 0.902567 6.60374 1.38258 7.19026C1.86259 7.77677 2.53071 8.17919 3.2736 8.32927C2.79032 8.46109 2.28325 8.48036 1.79136 8.38559C2.00121 9.0378 2.40962 9.60814 2.95949 10.0169C3.50937 10.4256 4.17322 10.6523 4.85824 10.6653C4.17763 11.2002 3.39821 11.5958 2.56458 11.8293C1.73096 12.0627 0.859476 12.1296 0 12.0259C1.50122 12.9893 3.24795 13.5002 5.03168 13.4979"
            fill="#1DA1F2"
        />
    </svg>
);

export const smallClock = (
    <svg
        width={12}
        height={12}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 1.33333C3.42267 1.33333 1.33333 3.42267 1.33333 6C1.33333 8.57733 3.42267 10.6667 6 10.6667C8.57733 10.6667 10.6667 8.57733 10.6667 6C10.6667 3.42267 8.57733 1.33333 6 1.33333ZM7.25711 7.25704L3.48586 8.51416L4.74295 4.74288L8.51416 3.48585L7.25711 7.25704Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 1.33333C3.42267 1.33333 1.33333 3.42267 1.33333 6C1.33333 8.57733 3.42267 10.6667 6 10.6667C8.57733 10.6667 10.6667 8.57733 10.6667 6C10.6667 3.42267 8.57733 1.33333 6 1.33333ZM0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6Z"
            fill="white"
        />
        <path
            d="M5.58095 6.41904C5.81237 6.65046 6.18758 6.65046 6.419 6.41904C6.65042 6.18762 6.65042 5.81241 6.419 5.58099C6.18758 5.34957 5.81237 5.34957 5.58095 5.58099C5.34952 5.81241 5.34952 6.18762 5.58095 6.41904Z"
            fill="white"
        />
    </svg>
);

export const like = (
    <svg
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.16732 14.6667V7.33337M0.833984 8.66671V13.3334C0.833984 14.0698 1.43094 14.6667 2.16732 14.6667H11.1181C12.1053 14.6667 12.9448 13.9465 13.0949 12.9708L13.8128 8.30416C13.9992 7.09264 13.0619 6.00004 11.8361 6.00004H9.50065C9.13246 6.00004 8.83398 5.70156 8.83398 5.33337V2.97727C8.83398 2.06937 8.09799 1.33337 7.19009 1.33337C6.97354 1.33337 6.7773 1.4609 6.68936 1.65879L4.34328 6.93747C4.23628 7.17822 3.99753 7.33337 3.73407 7.33337H2.16732C1.43094 7.33337 0.833984 7.93033 0.833984 8.66671Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

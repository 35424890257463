export const largeArrowAlt = (
    <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.3335 6H14.6668M14.6668 6L9.66683 1M14.6668 6L9.66683 11"
            stroke="var(--primary)"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const changelog = (
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 15.9998C4 16.3511 4 16.5268 4.01567 16.6795C4.14575 17.9473 5.0626 18.9945 6.30206 19.291C6.45134 19.3267 6.6255 19.3499 6.97368 19.3963L13.5656 20.2753C15.442 20.5254 16.3803 20.6505 17.1084 20.361C17.7478 20.1068 18.2803 19.6406 18.6168 19.0405C19 18.3569 19 17.4104 19 15.5174V6.48232C19 4.58928 19 3.64275 18.6168 2.95923C18.2803 2.35911 17.7478 1.89288 17.1084 1.63868C16.3803 1.34914 15.442 1.47423 13.5656 1.72442L6.97368 2.60335C6.62546 2.64978 6.45135 2.67299 6.30206 2.7087C5.0626 3.0052 4.14575 4.05241 4.01567 5.32018C4 5.47288 4 5.64854 4 5.99984M10 6.99984L14 10.9998M14 10.9998L10 14.9998M14 10.9998H1"
            stroke="#98A2B3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

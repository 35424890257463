export const downloads = (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.33464 12.5352C2.32965 11.8625 1.66797 10.7168 1.66797 9.41667C1.66797 7.46369 3.16089 5.85941 5.06775 5.68281C5.45781 3.31011 7.51817 1.5 10.0013 1.5C12.4844 1.5 14.5448 3.31011 14.9349 5.68281C16.8417 5.85941 18.3346 7.46369 18.3346 9.41667C18.3346 10.7168 17.673 11.8625 16.668 12.5352M6.66797 13.1667L10.0013 16.5M10.0013 16.5L13.3346 13.1667M10.0013 16.5V9"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const copy = (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.33301 9.99998C2.71175 9.99998 2.40112 9.99998 2.1561 9.89849C1.82939 9.76316 1.56983 9.50359 1.4345 9.17689C1.33301 8.93186 1.33301 8.62124 1.33301 7.99998V3.46665C1.33301 2.71991 1.33301 2.34654 1.47833 2.06133C1.60616 1.81044 1.81014 1.60647 2.06102 1.47864C2.34624 1.33331 2.7196 1.33331 3.46634 1.33331H7.99967C8.62093 1.33331 8.93156 1.33331 9.17659 1.43481C9.50329 1.57013 9.76286 1.8297 9.89818 2.1564C9.99967 2.40143 9.99967 2.71206 9.99967 3.33331M8.13301 14.6666H12.533C13.2797 14.6666 13.6531 14.6666 13.9383 14.5213C14.1892 14.3935 14.3932 14.1895 14.521 13.9386C14.6663 13.6534 14.6663 13.28 14.6663 12.5333V8.13331C14.6663 7.38658 14.6663 7.01321 14.521 6.72799C14.3932 6.47711 14.1892 6.27314 13.9383 6.1453C13.6531 5.99998 13.2797 5.99998 12.533 5.99998H8.13301C7.38627 5.99998 7.0129 5.99998 6.72769 6.1453C6.4768 6.27314 6.27283 6.47711 6.145 6.72799C5.99967 7.01321 5.99967 7.38658 5.99967 8.13331V12.5333C5.99967 13.28 5.99967 13.6534 6.145 13.9386C6.27283 14.1895 6.4768 14.3935 6.72769 14.5213C7.0129 14.6666 7.38627 14.6666 8.13301 14.6666Z"
            stroke="#344054"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

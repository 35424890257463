export const licenses = (
    <svg
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.3346 5.33335H1.66797M9.16797 8.66669H5.0013M1.66797 3.83335L1.66797 10.1667C1.66797 11.1001 1.66797 11.5668 1.84962 11.9233C2.00941 12.2369 2.26438 12.4919 2.57798 12.6517C2.9345 12.8334 3.40121 12.8334 4.33464 12.8334L15.668 12.8334C16.6014 12.8334 17.0681 12.8334 17.4246 12.6517C17.7382 12.4919 17.9932 12.2369 18.153 11.9233C18.3346 11.5668 18.3346 11.1001 18.3346 10.1667V3.83335C18.3346 2.89993 18.3346 2.43322 18.153 2.0767C17.9932 1.7631 17.7382 1.50813 17.4246 1.34834C17.0681 1.16669 16.6014 1.16669 15.668 1.16669L4.33464 1.16669C3.40122 1.16669 2.9345 1.16669 2.57798 1.34834C2.26438 1.50813 2.00941 1.7631 1.84962 2.0767C1.66797 2.43322 1.66797 2.89993 1.66797 3.83335Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

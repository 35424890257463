export const curvedArrow = (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.66699 12.3332V11.3998C1.66699 9.15963 1.66699 8.03952 2.10297 7.18388C2.48646 6.43123 3.09838 5.8193 3.85103 5.43581C4.70668 4.99984 5.82678 4.99984 8.06699 4.99984H12.3337M12.3337 4.99984L9.00033 8.33317M12.3337 4.99984L9.00033 1.6665"
            stroke="#2C67DB"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
